<template>
    <LobbyRow
        v-if="gifts.length !== 0"
        :title="$t(title)"
        :url="giftsUrl"
        class="gifts-row"
    >
        <div v-for="(item, index) in gifts" :key="index">
            <DepositGift
                v-if="cashbox"
                :gift="item"
                cashbox
                class="lobby-row__gift-item"/>
            <SuperGift v-else class="lobby-row__gift-item" :gift="item" />
        </div>
    </LobbyRow>
</template>

<script>
import { mapState } from "pinia";
import { routeNames } from "@router/routeNames";

import LobbyRow from "@components/LobbyRow/LobbyRow.vue";
import SuperGift from "@modules/Gifts/components/SuperGift/SuperGift.vue";
import DepositGift from "@modules/Gifts/components/DepositGift/DepositGift.vue";
import { useGiftsStore } from "@store/gifts";

export default {
    name: "GiftsRow",

    components: {
        LobbyRow,
        SuperGift,
        DepositGift,
    },

    props: {
        title: {
            type: String,
            default: "GIFT.MY_GIFTS",
        },

        cashbox: {
            type: Boolean,
            default: false,
        },

        url: {
            type: Object,
        },
    },

    computed: {
        ...mapState(useGiftsStore, [ "giftsAll" ]),

        giftsDeposit() {
            const { depositGifts } = useGiftsStore();
            return depositGifts.value;
        },

        gifts() {
            return (this.cashbox) ? this.giftsDeposit : this.giftsAll;
        },

        giftsUrl() {
            return { name: routeNames.profileGifts };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.gifts-row {
    --carousel-item-width: 20rem;

    @include media(M) {
        --carousel-item-width: 22rem;
    }
}
</style>
